import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const CategoryMenu = ({ color }) => {
  const data = useStaticQuery(graphql`
    query categoryMenuQuery {
      site: site {
        siteMetadata {
          title
          description
          menuLinks {
            link
            name
            items {
              link
              name
            }
          }
        }
      }
      category: allStrapiCategories {
        nodes {
          rollover_image {
            hover {
              publicURL
            }
            out {
              publicURL
            }
          }
          nom
          couleur {
            valeur
          }
        }
      }
    }
  `);
  const menuItems = data.site.siteMetadata.menuLinks[1].items; // fragile, depend de la position dans gatsby-config
  const categories = data.category.nodes;
  const menuStyle = {
    border: "5px",
    color: color,
    borderRadius: "5px",
    borderStyle: "solid",
    boxShadow: "1px 3px 2px #888888",
    padding: "0.5rem",
    marginBottom: "15px",
  };
  return (
    <div style={menuStyle}>
      {menuItems.map((item,index) => {
        const image = rolloverImage(categories, item.name);
        return (
          <MenuItem
            key={`catMenu_${index}`}
            title={item.name}
            url={item.link}
            imgSrcOut={image.out}
            imgSrcHover={image.hover}
            color={image.color}
          />
        );
      })}
    </div>
  );
};

const rolloverImage = (categories, categoryName) => {
  var rollover = { out: "", hover: "", color: "" };
  for (let category of categories) {
    if (category.nom === categoryName) {
      //console.log(category.nom);
      rollover.out = category.rollover_image.out.publicURL;
      rollover.hover = category.rollover_image.hover.publicURL;
      rollover.color = category.couleur.valeur;
    }
  }
  return rollover;
};
const imgStyle = {
  width: "40px",
  height: "40px",
  verticalAlign: "middle",
  borderRadius: "2px",
  marginRight: "10px",
};

class MenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      img: this.props.imgSrcOut,
      bColor: "white",
    };
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }
  handleMouseOver(event, color) {
    this.setState({
      img: this.props.imgSrcHover,
      bColor: color,
    });
  }

  handleMouseOut(event) {
    this.setState({
      img: this.props.imgSrcOut,
      bColor: "white",
    });
  }
  render() {
    const url = this.props.url;
    const title = this.props.title;
    const color = this.props.color;
    const aStyle = {
      fontSize: "1.2em",
      textDecoration: "none",
    };
    const spanStyle = {
      color: "black",
      backgroundColor: this.state.bColor,
      padding: "0.3rem",
    };
    return (
      <div>
        <a
          style={aStyle}
          href={url}
          onMouseOver={(event) => this.handleMouseOver(event, color)}
          onFocus={(event) => this.handleMouseOver(event, color)}
          onMouseOut={(event) => this.handleMouseOut(event)}
          onBlur={(event) => this.handleMouseOut(event)}
        >
          <div className="p-0 m-1 align-items-center rollover-item w-100 menu-item-2 ">
            <img
              className=" border-0 rollover-img"
              src={this.state.img}
              style={imgStyle}
              alt={title}
            />
            <span style={spanStyle}>{title}</span>
          </div>
        </a>
      </div>
    );
  }
}

export default CategoryMenu;
