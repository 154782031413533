import React from 'react';
import Markdown from 'markdown-to-jsx';
import { useStaticQuery, graphql } from 'gatsby';
import CategoryMenu from '../../components/page/categoryMenu';
import SidebarImageGallery from '../../components/page/SidebarImageGallery';

const monthNumber = {
  '01': 'janvier',
  '02': 'février',
  '03': 'Mars',
  '04': 'avril',
  '05': 'mai',
  '06': 'juin',
  '07': 'juillet',
  '08': 'aout',
  '09': 'septembre',
  10: 'octobre',
  11: 'Novembre',
  12: 'Décembre',
};

const formatTime = (time) => {
  const _arr = time.split(':');
  const heure = _arr[0];
  const minute = _arr[1] !== '00' ? _arr[1] : '';
  const formatted = `${heure}h${minute}`;

  return formatted;
};

const AnnualPlanning = ({ list, color, tarifs }) => {
  const months = Object.keys(list).sort((a, b) => Number(a) - Number(b));
  return (
    <>
      {months.map((month, index) => {
        const monthlyList = list[month];
        return (
          <MonthlyPlanning
            month={month}
            list={monthlyList}
            color={color}
            key={`mpKey_${index}`}
            tarifs={tarifs}
          />
        );
      })}
    </>
  );
};

const MonthlyPlanning = ({
  month, list, color, tarifs,
}) => {
  const category = Object.keys(list);
  const categoryList = list[category].items;
  const colorCategory = list[category].color;
  const _month = monthNumber[month].toUpperCase();


  const cStyle = {
    writingMode: 'vertical-rl',
    transform: 'rotate(-180deg)',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    borderColor: colorCategory,
    backgroundColor: colorCategory,
    borderRadius: '5px',
    borderStyle: 'solid',
  };

  return (
    <div>
      <a className="anchor" id={_month} />
      <div className="row m-3">
        <div className="col-1 p-1" style={cStyle}>
          {_month}
        </div>
        <div className="col-11">
          <CategoryPlanning
            category={category}
            list={categoryList}
            color={colorCategory}
            tarifs={tarifs}
          />
        </div>
      </div>
    </div>
  );
};
const CategoryPlanning = ({
  category, list, color, tarifs,
}) => {
  const categoryStyle = {
    border: '5px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    // boxShadow: "1px 3px 2px #888888",
    padding: '0.5rem',
    textAlign: 'center',
  };
  return (
    <div className="row">
      {list.map((item, index) => (
        <DailyPlanning
          item={item}
          key={`dKey_${index}`}
          tarifs={tarifs}
          color={color}
        />
      ))}
    </div>
  );
};
const DailyPlanning = ({ item, tarifs, color }) => {
  const itemStyle = {
  };
  const pStyle = {

    border: '3px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
  };
  const sStyle = {
    padding: '0.5rem',
  };
  const tarifStyle = {
    border: '5px',
    borderColor: color,
    backgroundColor: color,
    width: '100%',
    textAlign: 'center',
  };
  const desc = `**${formatTime(item.horaires.debut)}-${formatTime(
    item.horaires.fin,
  )}** : ${item.description}`;

  const itemCategory = item.categorie.id;
  const tarifCategory = tarifs.filter((item) => item.category.id === itemCategory).map((item) => {
    if (item.periode === 'matin') {
      item.periode = 'Matin';
    }
    if (item.periode === 'apresmidi') {
      item.periode = 'Après-midi';
    }
    if (item.periode === 'journee') {
      item.periode = 'Journée';
    }
    return item;
  });

  return (
    <div style={itemStyle} className="col-12 col-md-6 mb-2">
      <div style={pStyle}>
        <div className="m-2">
          <h4>{item.nom}</h4>
          <div>
            <Markdown>{desc}</Markdown>
          </div>
        </div>


        {!item.details_tarif ? (
          <></>
        ) : (
          <>
            <div>
              <Markdown>{item.details_tarif}</Markdown>
            </div>
          </>
        )}

        {item.grilleTarif ? <div style={tarifStyle}>
          <div style={sStyle}>
            {tarifCategory.map((tarif, index) => (
              <span key={`tc_${index}`}>
                {tarif.periode}
                {' '}
                :
                {' '}
                {tarif.tarif}
                €
                {tarifCategory.length - (index + 1) !== 0 ? ' / ' : ''}
              </span>
            ))}
          </div>
        </div> : <></>}
      </div>
    </div>
  );
};

const Planning = ({
  title, anchor, color, stageList, colorList, images,
}) => {
  const data = useStaticQuery(graphql`
    query tarifStagesQuery {
      allStrapiTarifStages {
        nodes {
          category {
            id
          }
          horaire_debut
          horaire_fin
          id
          periode
          tarif
        }
      }
    }
  `);

  const { allStrapiTarifStages } = data;
  const colors = colorList.reduce((acc, color) => {
    const id = color.id.split('_')[1];
    acc[id] = color.valeur;
    return acc;
  }, {});
  const availablesStages = getAvailablesStages(stageList, colors);

  const years = Object.keys(availablesStages).sort(
    (a, b) => Number(a) - Number(b),
  );

  return (
    <div className="row">
      <div>
        <a className="anchor" id={anchor} />
        <button className="link-button" />
        {years.map((year, index) => {
          const annualList = availablesStages[year];
          return (
            <AnnualPlanning
              list={annualList}
              color={color}
              key={`aKey_${index}`}
              tarifs={allStrapiTarifStages.nodes}
            />
          );
        })}
      </div>
    </div>
  );
};

const getAvailablesStages = (stageList, colors) => {
  /*
on prepare la donnée en fonction des conditions
le stage doit etre actif, la date du jour >= la date de publication
Il faut regrouper les stages par mois et années
*/
  // console.log(colors);
  const availables = stageList
    .filter((item) => item.categorie != null)
    .filter((item) => {
      const date_str = `${item.date_publication}T00:00:01`;
      const datePub = new Date(date_str);
      const dateFin = new Date(`${item.fin}T22:00:00`);
      const isPublishable = Date.now() >= datePub && dateFin >= Date.now();
      return isPublishable;
    })
    .sort((a, b) => {
      const dateA = new Date(`${a.debut}T12:00:00`);
      const dateB = new Date(`${b.debut}T12:00:00`);
      return dateA - dateB;
    })
    .reduce((acc, item) => {
      const year = item.debut.split('-')[0];
      const month = item.debut.split('-')[1];
      if (!acc.hasOwnProperty(year)) {
        acc[year] = {};
      }
      if (!acc[year].hasOwnProperty(month)) {
        acc[year][month] = {};
      }
      const category = item.categorie.nom;

      if (!acc[year][month].hasOwnProperty(category)) {
        acc[year][month][category] = {};
      }
      if (!acc[year][month][category].hasOwnProperty('items')) {
        acc[year][month][category].items = [];
      }
      if (acc[year][month][category].color === undefined) {
        acc[year][month][category].color = colors[item.categorie.couleur];
      }
      acc[year][month][category].items.push(item);
      return acc;
    }, {});
  // console.log(availables);
  return availables;
};
export default Planning;
