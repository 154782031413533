import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Image from "gatsby-image";

export default class SidebarImageGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const images = this.props.images;
    //console.log(images);
    const imageslist = images.filter((item)=>{return item.image != null}).map((item, index) => {
      
      return (
        <div key={`si_${index}`} className="card">
          <button
            className="link-gallery"
            onClick={() => this.setState({ isOpen: true, photoIndex: index })}
            onKeyDown={() => this.setState({ isOpen: true, photoIndex: index })}
          >
            <Image
              className="card-img"
              style={{ width: "100%" }}
              key={item.image.publicURL}
              fluid={item.image.childImageSharp.fluid}
              alt={item.nom}
            />
          </button>
        </div>
      );
    });
    const imagesBox = images.filter((item)=>{return item.image != null}).map((item) => {
      return item.image.publicURL;
    });
    const imageTitles = images.map((item) => {
      return item.nom;
    });
    return (
      <>
        {imageslist}

        {isOpen && (
          <Lightbox
            mainSrc={imagesBox[photoIndex]}
            nextSrc={imagesBox[(photoIndex + 1) % imagesBox.length]}
            prevSrc={
              imagesBox[(photoIndex + imagesBox.length - 1) % imagesBox.length]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + imagesBox.length - 1) % imagesBox.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imagesBox.length,
              })
            }
            imageTitle={imageTitles[photoIndex]}
            imageCaption={imageTitles[photoIndex]}
          />
        )}
      </>
    );
  }
}
