import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Description from '../../components/paragraphs/descriptionStages';
import Headings from '../../components/paragraphs/headings';
import Details from '../../components/paragraphs/details';
import Planning from './planningStages';

const Stages = ({
  id,
  title,
  description,
  details,
  nota_bene,
  imgPresentation,
  imgDesc,
  images,
  icon,
  color,
  paragraphs,
  ateliers,
  stageList,
  colorList,
  meta,
  pageContext,
}) => (
  <Layout title={title} buildTime={pageContext.buildTime}>
    <SEO meta={meta} />
    <Headings
      title={title}
      imgPresentation={imgPresentation}
      icon={icon}
      color={color}
      paragraphs={paragraphs}
      stageList={stageList}
      meta={meta}
      pageContext={pageContext}
    />

    <Description
      id={id}
      title={title}
      description={description}
      details={details}
      imgDesc={imgDesc}
      ateliers={ateliers}
      stageList={stageList}
      colorList={colorList}
      color={color}
      anchor="description_des_stages"
    />
  </Layout>
);

export default function Template({ data, pageContext }) {
  const { page } = data;
  const stageList = data.stages.nodes;
  const colorList = data.couleurs.nodes;
  const { couleur } = page;
  const pageDescription = `${page.description}`;
  const pageDetails = `${page.details}`;
  const { image_presentation } = page;
  const { images } = page;
  const { icon } = page;
  const imgDesc = page.img_desc;
  const paragraphs = [
    {
      key: 'calendrier',
      name: 'Calendrier',
      color: page.couleur.valeur,
    },
    {
      key: 'description_des_stages',
      name: 'Description des stages',
      color: page.couleur.valeur,
    },
  ];


  return (
    <Stages
      id={page.id}
      title={page.meta.title}
      description={pageDescription}
      details={pageDetails}
      nota_bene={page.nota_bene}
      imgPresentation={image_presentation}
      imgDesc={imgDesc}
      images={images}
      icon={icon}
      paragraphs={paragraphs}
      color={couleur.valeur}
      ateliers={page.description_des_stages}
      stageList={stageList}
      colorList={colorList}
      meta={page.meta}
      pageContext={pageContext}
    />
  );
}

export const pageQuery = graphql`
  query stagesQuery {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    page: strapiStagesDessinModelage {
      id
      description
      details
      nota_bene
      couleur {
        valeur
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_desc {
        nom
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      description_des_stages {
        nom
        contenu
        category {
          id
          nom
        }
        id
        images {
          nom
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      images {
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        nom
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
              width
              height
              src
            }
          }
        }
      }
    }
    stages: allStrapiStages(filter: { actif: { eq: true } }) {
      nodes {
        date_publication
        debut
        description
        details_tarif
        fin
        nom
        horaires {
          debut
          fin
        }
        categorie {
          couleur
          nom
          id
        }
        grilleTarif
      }
    }
    couleurs: allStrapiCouleurs {
      nodes {
        id
        nom
        valeur
      }
    }
  }
`;
