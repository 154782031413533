import PropTypes from 'prop-types';
import React from 'react';
import Image from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import urlSlug from 'url-slug';
import ImageGallery from '../page/ImageGallery';
import Planning from '../../templates/stages/planningStages';
import CoursCards from './coursCards';

const Atelier = ({
  key, id, title, content, color, images, detailsTarif, stageList, colorList,
}) => {
  const tStyle = {
    WebkitTextDecoration: `${color} solid underline`,
    textDecoration: `${color} solid underline`,
    WebkitTextUnderlinePosition: 'under',
    textUnderlinePosition: 'under',
    fontWeight: 'bold',
    padding: '10px',
  };
  const aStyle = {
    marginTop: '10px',
    padding: '10px',
  };
  const cStyle = {
    padding: '10px',
    textAlign: 'justify',
  };
  const anchorStyle = {
    display: 'block',
    position: 'relative',
    top: '-50px',
    visibility: 'hidden',
  };

  return (
    <div key={key} style={aStyle}>
      <a style={anchorStyle} id={id} />
      <h3 style={tStyle}>{title}</h3>
      <div style={cStyle}>
        <Markdown>{content}</Markdown>
      </div>
      <Planning
        title="Calendrier"
        anchor="calendrier"
        color={color}
        stageList={stageList}
        colorList={colorList}
        images={images}
      />
      <div className="d-flex flex-wrap">
        <ImageGallery images={images} />
      </div>
    </div>
  );
};
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


const Description = ({
  id, title, description, days, anchor, ateliers, color, imgDesc, detailsTarif, stageList, colorList,
}) => {
  const dStyle = {
    textAlign: 'justify',
    border: '5px',
    borderColor: color,
    backgroundColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    marginBottom: '1rem',
    marginTop: '10px',
  };
  const aStyle = {

    textAlign: 'justify',
    border: '5px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    marginBottom: '1rem',
  };

  const cStyle = {

    textAlign: 'justify',
    border: '5px',
    borderColor: 'white',
    borderRadius: '5px',
    marginBottom: '1rem',
    marginTop: '10px',
  };
  return (
    <div>
      <div className="row">
        <div style={dStyle} className="col-lg-6 col-12">
          <div>

            <a className="anchor" id={anchor} />
            <button className="link-button" />
            <h2
              className=" text-center "
              style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
            >
              {title}
            </h2>
          </div>
          <div><Markdown>{description}</Markdown></div>
        </div>
        <div style={cStyle} className="col-lg-6 col-12 container">
          <CoursCards id={id} />
        </div>
      </div>


      <div style={aStyle} className="row">
        {ateliers.map((item) => {
          const anchor = urlSlug(item.nom);


          // on filtre les stages par categorie
          const atelierCategoryId = item.category.id;
          const catStageList = stageList.filter((item) => {
            const itemCategoryId = item.categorie.id;
            return atelierCategoryId === itemCategoryId;
          });
          return (
            <div key={`a_${item.id}`}>
              <Atelier
                title={item.nom}
                content={item.contenu}

                images={item.images}
                color={color}
                id={anchor}
                detailsTarif={detailsTarif}
                stageList={catStageList}
                colorList={colorList}
              />
            </div>
          );
        })}

      </div>

    </div>
  );
};


export default Description;


const getSeances = (atelier, days) => {
  try {
    const isAtelierLibre = atelier.nom.indexOf('libre') !== -1;
    const seances = days.reduce((acc, currentDay) => {
      currentDay.horaires.forEach((seance) => {
        const seanceIsLibre = seance.nom.indexOf('libre') !== -1;
        if (seanceIsLibre === isAtelierLibre) {
          seance.jour = currentDay.jour;
          acc.push(seance);
        }
      });

      return acc;
    }, []);

    return seances;
  } catch (error) {
    console.log(error);
    [];
  }
};


Description.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  anchor: PropTypes.string,
  ateliers: PropTypes.arrayOf(
    PropTypes.shape({
      nom: PropTypes.string,
      contenu: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string,
          image: PropTypes.shape({
            publicURL: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  color: PropTypes.string,
  imgDesc: PropTypes.shape({
    nom: PropTypes.string,
    image: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
  }),
};

Description.defaultProps = {
  title: '',
  details: '',
  anchor: '',
  ateliers: [],
  color: '',
  imgDesc: PropTypes.shape({
    nom: '',
    image: PropTypes.shape({
      publicURL: '',
    }),
  }),
};
